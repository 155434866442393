import React, { useState } from "react"
import CartItem from "./CartItem"
import { prefixWord } from "./../../utils"

const Cart = ({ onClose, cart, increase, decrease }) => {
  let prices = cart.map(item => item.quantity * item.price)

  let cartTotal = prices.length
    ? prices.reduce((current, acc) => current + acc)
    : 0

  const disabled = !Boolean(cart.length)

  const getActionLabel = () => {
    let label = "Place order"

    return label
  }
  return (
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          class="inline-block  align-middle bg-white  rounded-lg text-left min-w-lg overflow-hidden  shadow-xl transform transition-all sm:my-8  w-full lg:w-vw-1/3 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center  w-12 h-12 rounded-full bg-yellow-600  sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  style={{ width: 24, height: 24 }}
                  class="text-white"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z"
                  />
                </svg>
              </div>
              <div class=" text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg  mt-1 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {cart.length} {prefixWord("item", cart.length, "s")} added to
                  order
                </h3>
              </div>
            </div>
          </div>

          <div class="py-4 overflow-y-scroll  max-h-96 ">
            {cart.map(item => (
              <CartItem
                key={item.id}
                decrease={decrease}
                increase={increase}
                recipe={item}
              />
            ))}
          </div>

          <div class=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse font-medium text-lg">
            {cart.length} {prefixWord("item", cart.length, "s")} = RWF{" "}
            {Number(cartTotal).toLocaleString()}
          </div>

          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            {/* <button
              disabled={disabled}
              type="button"
              class={`  w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${
                disabled ? "bg-gray-400" : "bg-yellow-600 "
              }  text-base font-medium text-white hover: ${
                disabled ? "bg-gray-400" : "bg-yellow-700"
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm`}
            >
              <span class="text-base"> {getActionLabel()} </span>
            </button> */}
            <button
              onClick={onClose}
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              <span class="text-base"> Cancel </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cart
