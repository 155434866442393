export const menuSoftDrink = {
  sodas: {
    id: 1,
    recipes: [
      {
        id: "d3b74875-aab2-40cf-8a9e-6a0800ff2a1e",
        title: "Coke",
        price: 1000,
      },

      {
        id: "07d28915-9a87-4936-8ccb-3f1a793070a2",
        title: "Coke Zero",
        price: 1000,
      },

      {
        id: "abf2f108-b0ad-4dc1-8a50-4cc0e22484eb",
        title: "Fanta Citron",
        price: 1000,
      },

      {
        id: "cee437b9-a24f-4c9b-9ea9-bc6305784259",
        title: "Fanta Fiesta",
        price: 1000,
      },

      {
        id: "56862e2a-0cb1-491c-b946-0a5daeed347f",
        title: "Fanta Orange",
        price: 1000,
      },

      {
        id: "252bdcd1-26c0-47cb-836e-b512237c3ded",
        title: "Fanta Tonic",
        price: 1000,
      },

      {
        id: "27d4b62a-a4ef-4fea-8358-1a40a4a79e00",
        title: "Fanta Sprite",
        price: 1000,
      },
    ],
  },

  water: {
    id: 2,
    recipes: [
      {
        id: "552c9870-e90f-4b57-9e38-27d6bda1a4d4",
        title: "Water (30ml)",
        price: 1000,
      },
    ],
  },

  others: {
    id: 3,
    recipes: [
      {
        id: "85d3a61a-499e-4b48-b560-823cfe833b89",
        title: "Panache",
        price: 1000,
      },

      {
        id: "8e668120-8fd3-432f-b063-daf9283c4e5a",
        title: "Bavaria",
        price: 3000,
      },

      {
        id: "9922d21f-c21d-4c94-98f1-c9fc9415c5cc",
        title: "Red Bull",
        price: 3000,
      },
    ],
  },
}

export const menuSoftDrinkLabels = [
  {
    id: 1,
    value: "sodas",
    label: "Sodas",
  },
  {
    id: 2,
    value: "water",
    label: "Water",
  },

  {
    id: 3,
    value: "others",
    label: "Others",
  },
]
