import React, { useState } from "react"
import {
  TopNav,
  SidebarNav,
  MenuList,
  Order,
  CartCount,
  Cart,
} from "../components/menu"
import { types, labels, menu } from "../data/menu"
import { menuFood, foodMenuLabels } from "../data/food"
import { menuSoftDrink } from "../data/soft-drinks"
import { convertArrayToObject, removeObjectKey } from "../utils"
import { Layout } from "../components/layout"
import { Head } from "../components/commons"

const MenuPage = () => {
  const [type, setType] = useState("food")

  const [typeMenu, setTypeMenu] = useState(menu[type])
  const [category, setCategory] = useState(labels[type][0].value)
  const [cart, setCart] = useState({})

  const [isOrderOpen, setOrderOpen] = useState(false)
  const [selectedRecipe, setSelectedRecipe] = useState(null)
  const [isCartOpen, setCartOpen] = useState(null)

  const onOrderClose = () => setOrderOpen(false)
  const onCartClose = () => setCartOpen(false)

  const toggleCart = () => setCartOpen(!isCartOpen)

  const changeCart = item => {
    setSelectedRecipe(item)
    setOrderOpen(true)
  }
  const onCart = recipe => {
    const isRemoved = Boolean(recipe.quantity === 0)
    let newCart = []
    if (isRemoved) {
      newCart = removeObjectKey(cart, recipe.id)
      setCart(newCart)
    } else {
      newCart = { ...cart, [recipe.id]: recipe }
      setCart(newCart)
    }

    setOrderOpen(false)
  }

  const increaseInCart = item => {
    const prevItem = cart[item.id]
    const prevQuantity = prevItem.quantity
    const newQuantity = prevQuantity + 1

    const newItem = { ...prevItem, quantity: newQuantity }

    let newCart = { ...cart, [item.id]: newItem }

    setCart(newCart)
  }
  const decreaseInCart = item => {
    const prevItem = cart[item.id]
    const prevQuantity = prevItem.quantity
    const newQuantity = prevQuantity - 1

    let newCart = {}
    if (newQuantity === 0) {
      newCart = removeObjectKey(cart, item.id)
      setCart(newCart)
    }
    if (newQuantity >= 1) {
      const newItem = { ...prevItem, quantity: newQuantity }
      newCart = { ...cart, [item.id]: newItem }
      setCart(newCart)
    }
  }

  return (
    <Layout>
      <Head title="Home" image={{ src: "/images/hero-img.jpg" }} />
      <div class="px-4 lg:px-20 pt-20">
        <div class="flex items-center justify-between lg:pt-20 pt-5">
          <TopNav
            links={types}
            onChange={link => {
              setType(link)
              setCategory(labels[link][0].value)
            }}
            activeCategory={type}
          />

          {Object.values(cart).length ? (
            <CartCount cart={Object.values(cart)} onClick={toggleCart} />
          ) : null}
        </div>

        <div class="grid grid-row-1 grid-cols-12 gap-4 lg:py-12 ">
          <div class=" col-span-6 lg:col-span-2">
            <SidebarNav
              data={labels[type]}
              activeCategory={category}
              onChange={element => setCategory(element)}
            />
          </div>

          <div class="col-span-6 lg:col-span-10">
            <MenuList
              data={menu[type][category]?.recipes || []}
              cart={Object.values(cart)}
              changeCart={changeCart}
            />
          </div>
        </div>

        {selectedRecipe && isOrderOpen && (
          <Order
            onClose={onOrderClose}
            onCart={onCart}
            recipe={selectedRecipe}
            cart={Object.values(cart)}
          />
        )}

        {isCartOpen && (
          <Cart
            onClose={onCartClose}
            onCart={onCart}
            recipe={selectedRecipe}
            cart={Object.values(cart)}
            increase={increaseInCart}
            decrease={decreaseInCart}
          />
        )}
      </div>
    </Layout>
  )
}

export default MenuPage
