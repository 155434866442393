import React from "react"
import { truncate, isMobileDevice } from "../../utils"
const CartItem = ({ decrease, increase, recipe }) => {
  return (
    <div class=" grid grid-rows-1 grid-cols-12 gap-1 px-4 my-2">
      <div class="lg:my-1 my-4 col-span-5 ">
        <span class="text-lg font-medium">
          {" "}
          {truncate(recipe.title, isMobileDevice() ? 20 : 30)}
        </span>
      </div>

      <div class="lg:my-1 my-4 col-span-4 flex items-center justify-center">
        <span class="text-lg font-medium">
          {" "}
          {Number(recipe.price).toLocaleString()} x {recipe.quantity}
        </span>
      </div>

      <div class="col-span-3  flex flex-col justify-center  items-center lg:flex-row lg:justify-around  ">
        <button
          onClick={() => decrease(recipe)}
          type="button"
          class="flex items-center justify-center rounded-full  w-8 h-8 shadow-sm p-2  bg-yellow-100 text-yellow-600 text-2xl font-medium  hover:bg-gray-50 focus:outline-none  "
        >
          <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
            <path fill="currentColor" d="M19,13H5V11H19V13Z" />
          </svg>
        </button>

        <button
          onClick={() => increase(recipe)}
          type="button"
          class=" flex items-center justify-center my-1 rounded-full  w-8 h-8 shadow-sm p-2 bg-yellow-100 text-yellow-600 text-2xl font-medium  hover:bg-gray-50 focus:outline-none  "
        >
          <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default CartItem
