import React from "react"
import { types } from "../../data/menu"
const TopNav = ({ links, activeCategory, onChange }) => {
  return (
    <ul>
      {links.map(link => {
        const labelColor =
          activeCategory === link.value ? "text-red-700" : "text-gray-700"
        const labelWeight =
          activeCategory === link.value ? "font-medium" : "font-normal"
        return (
          <li key={link.id} class="inline mr-4 cursor-pointer">
            <span
              onClick={() => onChange(link.value)}
              class={`${labelColor} ${labelWeight}  leading-8 text-base`}
            >
              {link.label}
            </span>
          </li>
        )
      })}
    </ul>
  )
}

TopNav.defaultProps = {
  links: types,
  onChange: () => console.warn("Attach onChange event handler"),
  activeCategory: "food",
}

export default TopNav
