import React from "react"
import MenuItem from "./MenuItem"

const MenuList = ({ data, changeCart, cart }) => {
  console.log(cart)
  const isInCart = itemID => {
    return Boolean(cart.find(item => item.id === itemID))
  }
  return (
    <div class="flex  flex-wrap">
      {data.map(recipe => {
        return (
          <MenuItem
            key={recipe.id}
            {...recipe}
            changeCart={changeCart}
            inCart={isInCart(recipe.id)}
          />
        )
      })}
    </div>
  )
}

export default MenuList
