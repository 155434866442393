export const menuFood = {
  breakfast: {
    id: 1,
    recipes: [
      {
        id: "2555aceb-af47-4012-8136-286c4f5c278d",
        title: "Breakfast Platte",
        price: 10000,
      },

      {
        id: "e0169088-4ff7-4461-bbc3-3289cb3f991d",
        title: "Plain Omelette ",
        price: 3000,
      },

      {
        id: "8414163f-bcd2-4e7d-8986-6716390f3ba2",
        title: "Cheese Omelette ",
        price: 3000,
      },

      {
        id: "aa931a8d-e310-44b0-a58e-92d5e917e93b",
        title: "Spanish Omelette",
        price: 3000,
      },

      {
        id: "e5361d33-4140-4bdd-86fe-ba102de0c625",
        title: "Special Omelette",
        price: 5000,
      },

      {
        id: "4384b47f-4ede-4c2c-8256-20c4a931085e",
        title: "Fruit starter",
        price: 4000,
      },
    ],
  },

  starters: {
    id: 2,
    recipes: [
      {
        id: "26172b4e-5c27-4b54-937a-8c53db49b848",
        title: "Beef Mince Koftas (two) with Chips ",
        price: 3000,
      },
      {
        id: "20ca9f8a-c156-4e6a-ac25-39aa0a5a7a6f",
        title: "Beef Mince Samosa (two) with Chips ",
        price: 3000,
      },
      {
        id: "cbf16dd4-962e-481b-9fd7-87b5a135e0a7",
        title: "Beef Cutlets (two) with Chips ",
        price: 3000,
      },

      {
        id: "a7d0fc17-3ac7-4e5a-99ca-385ef2885627",
        title: "Vegetable Samosa (two) with Chips ",
        price: 3000,
      },
      {
        id: "f5956388-6294-4ee6-9dc0-fc32bb1af28b",
        title: "Vegetable Cutlets (two) with Chips  ",
        price: 3000,
      },

      {
        id: "24aa8101-111e-4ba4-8d9f-ce3d1701a235",
        title: "Chicken 65  ",
        price: 6000,
        desc:
          "(Chicken marinated in aromatic Indian spices, Fried tossed in curry leaves, slivered green chillies and lemon juice)",
      },

      {
        id: "f420151a-8f35-439f-9159-dc6e49f59178",
        title: "Chicken Lollipop  ",
        price: 5000,
      },

      {
        id: "32baad07-f351-4e91-8054-3b2e596756af",
        title: "Fish Fingers  ",
        price: 5000,
      },
      {
        id: "e181345e-21e1-480b-9b80-11e5fc24dc46",
        title: "Sambaza",
        price: 3000,
      },

      {
        id: "1d4644a9-88dd-484b-a35a-437eb88c2fe8",
        title: "Quesadillas",
        price: 5000,
      },
      {
        id: "a1ecd396-e6ae-44b5-af0e-1691cff79949",
        title: "Nachos",
        price: 5000,
      },
    ],
  },
  salads: {
    id: 3,
    recipes: [
      {
        id: "e917f92a-38f3-4650-89e6-52538284a52d",
        title: "Avocado Salsa Salad",
        price: 3000,
      },
      {
        id: "88371009-9586-45df-88cd-ed1ccd8005c9",
        title: "Avocado Vinegrette ",
        price: 3000,
      },

      {
        id: "d045c42b-9a70-44f7-ad2f-adc7673f6976",
        title: "Crisp Garden Salad ",
        price: 3000,
      },

      {
        id: "916a1663-1a66-412e-bd21-9047deb0031a",
        title: "Greek Salad/Chicken Salad ",
        price: 4000,
      },

      {
        id: "28a3f61e-bbc4-45e0-9ebd-6881a73b1698",
        title: "Sweet Corn Avocado Salad ",
        price: 3500,
      },

      {
        id: "1efa5c80-a788-4f75-be89-4925d27b1c3e",
        title: "Chef Salad ",
        price: 5000,
      },
      {
        id: "4630116c-be9b-46af-8a54-61a2b71fad05",
        title: "Mixed Salad",
        price: 4000,
      },
    ],
  },

  soups: {
    id: 4,
    recipes: [
      {
        id: "84cedb24-d425-4456-943b-0f9572a72989",
        title: "Carrot & Ginger Soup ",
        price: 2500,
      },

      {
        id: "c1b0f382-d854-4ce1-8819-7f38309f86db",
        title: "Cream of Chiken Soup ",
        price: 3000,
      },

      {
        id: "f4282402-fc6f-4951-8585-3bdb0d68886a",
        title: "Mixed Vegetable Soup ",
        price: 2500,
      },

      {
        id: "66d50cbd-c16e-4239-85d6-f996309c3ccc",
        title: "Fish Soup",
        price: 3000,
      },

      {
        id: "fb007664-930a-457a-8f46-6a4eb2cd4bbd",
        title: "Sweet and Sour Soup",
        price: 4000,
      },
      {
        id: "e19a4a6c-f658-4dfb-af86-30c88f152034",
        title: "Tomato Soup",
        price: 2500,
      },
    ],
  },
  brochettes: {
    id: 5,
    recipes: [
      {
        id: "2bd6a457-ea49-421e-882a-b1563634fceb",
        title: " Chicken/Fish/Beef Brochette(Two, with Chips)",
        price: 7000,
      },

      {
        id: "ee7c1b25-71e2-4a6f-974f-d45a65e16500",
        title: "Goat Brochette (Two, with Chips) ",
        price: 6000,
      },
      {
        id: "122b1a44-c631-4d43-b6e1-fda2d0327b69",
        title: "Beef Brochette (Two, with Chips) ",
        price: 7000,
      },

      {
        id: "19610caa-202b-4903-9bbe-8c2dca0aca31",
        title: "Ikidari",
        price: 12000,
      },
    ],
  },

  beefs: {
    id: 6,
    recipes: [
      {
        id: "5ba90709-4edc-40e1-91a7-e4bf0c9f45c5",
        title: "Steak ",
        price: 8000,
        desc: "(With your choice of Mushroom/Green pepper sauce)",
      },

      {
        id: "08260fb3-9b8f-4c1b-aebb-5c555debed95",
        title: "Beef Stew",
        price: 8000,
      },

      {
        id: "f2a3ea79-c650-473b-aa51-cb383a4bcb35",
        title: "Sizziling Beef with Green Pepper",
        price: 8000,
      },

      {
        id: "7a79f7a0-d4f1-44d5-bcdd-7390d611b4a2",
        title: "Beef Trinchado",
        price: 8000,
        desc:
          "(A traditional South African braised beef dish with strong Portuguese influences, spicy and very rich)        ",
      },
    ],
  },
  porks: {
    id: 7,
    recipes: [
      {
        id: "5e643077-dbf2-48cb-9924-5adad8b9cfbe",
        title: "Grilled Rack of Ribs",
        price: 10000,
        desc:
          "(Flavoured with our secret dry rub, slow cooked to perfection & finished on the grill with a sweet sticky sauce)",
      },
      {
        id: "57f7a132-d827-456f-8c33-52ff738676d5",
        title: "Pork Akabenzi 1 kg (House Speciality) ",
        price: 12000,
      },

      {
        id: "1fe14ad6-015a-430b-a6c7-d270e70ef456",
        title: "Pork Akabenzi Half Kg ",
        price: 8000,
      },

      {
        id: "a02a5869-1728-4a1c-8e77-8527a60d0260",
        title: "Pork Vindaloo ",
        price: 7000,
        desc: "(Mild/Hot pork curry served with fragrant Basmati Rice)",
      },
    ],
  },
  chickens: {
    id: 8,
    recipes: [
      {
        id: "ce9bc3b3-12df-4fe4-8085-9c1d3dce7a0a",
        title: "Grilled Chicken – Whole ",
        price: 15000,
      },

      {
        id: "d3962ab4-01c9-4612-9d08-bbd6535c6636",
        title: "Grilled Chicken – Half ",
        price: 8000,
      },

      {
        id: "2340a4ae-14d4-49d2-908a-560a89333d28",
        title: "Grilled Chicken – Quarter ",
        price: 6000,
      },
    ],
  },

  fishes: {
    id: 9,
    recipes: [
      {
        id: "1bb74499-1b86-4a3b-af9a-ab944fc89d70",
        title: "Fish Fillet with Sauce ",
        price: 8000,
      },

      {
        id: "295cd4c9-96c5-4d5e-ba33-542bacff7e11",
        title: "Grilled Whole Tilapia – Chef’s Special ",
        price: 8000,
      },

      {
        id: "7dcbf541-bf31-447c-8c94-0c2d3f17803f",
        title: "Fish Fingers",
        price: 4000,
      },

      {
        id: "667b2e9b-c3c4-4dfe-99cf-c43ed808c71c",
        title: "Crumb Fried Tilapia",
        price: 8000,
      },
    ],
  },

  rwanda: {
    id: 10,
    recipes: [
      {
        id: "dfd03d46-e4d9-48f7-b491-cc8c3894f982",
        title: "Sambasa Stew with Ugali or Kawunga",
        price: 8000,
      },

      {
        id: "71791008-cc3b-4ffc-9b3a-5d418f8a2591",
        title: "Tilapia Stew with Ugali or Kawunga",
        price: 10000,
      },

      {
        id: "df77e2cc-8a82-4ba7-8f5a-773bc09fb914",
        title: "Beef Stew with Ugali or Kawunga",
        price: 8000,
      },
      {
        id: "af3c9dc3-327e-491f-8dfe-a68191edbd47",
        title: "Igisafuriya",
        price: 30000,
        desc: "(Pot of Chicken in Sauce & Veggies, serves 4) ",
      },

      {
        id: "3b234321-626c-4496-bdd8-dde419bb68fc",
        title: "Plate of Pumpkin and Peas",
        price: 5000,
      },

      {
        id: "c60c8edf-1af4-42da-ac2e-1ed0b4878d39",
        title: "Vegetable Plate",
        price: 5000,
      },
      {
        id: "618f95e1-e7bf-4fce-b1dc-06c3c9440b9a",
        title: "Rwanda Plate",
        price: 8000,
      },
    ],
  },
  accompaniments: {
    id: 11,
    recipes: [
      {
        id: "dca4ae12-c799-4293-9534-4b013656c501",
        title: " Banana -(Grilled or Roasted)",
        price: 0,
      },

      {
        id: "897705ce-91c1-4530-b8b4-3911df75553d",
        title: "Potatoes -(Boiled/Grilled/Mashed/Chips",
        price: 0,
      },

      {
        id: "c47ee05b-65ce-4812-95c9-3ab3f4d58df7",
        title: " Sauces (Garlic/Lemon/Mushroom/Pepper)",
        price: 0,
      },
    ],
  },

  eastern: {
    id: 12,
    recipes: [
      {
        id: "351ec2af-3f5f-41f0-8011-636edb3bfb5f",
        title: "Biryani-Chicken/Goat",
        price: 6000,
      },

      {
        id: "6dfdd61d-ae82-4566-a525-8c72dea05894",
        title: "Biryani-Veg ",
        price: 5000,
      },
      {
        id: "4aca2968-3c2d-4dfc-a460-9b4921246b35",
        title: " Tandoori Chicken Full ",
        price: 15000,
      },
      {
        id: "ccb07367-4a52-452b-abd6-82ea4edd6345",
        title: "Tandoori Chicken Quarter ",
        price: 8000,
      },

      {
        id: "1df491b5-cc49-4e13-8cf8-87aea7689588",
        title: " Chicken Curry (Portion",
        price: 6000,
      },

      {
        id: "2d0d6210-7f95-420c-aadf-e19e5d72d481",
        title: "Butter Chicken (Portion)",
        price: 6000,
      },

      {
        id: "9e691c73-67b6-4b40-bb81-68cf79062cf0",
        title: "Malai Kofta (Veg)",
        price: 5000,
      },

      {
        id: "0e3bbbf7-41cd-41b3-aeba-951a2d335bb4",
        title: "Mixed Veg Curry",
        price: 5000,
      },

      {
        id: "46801cbf-de98-49d1-a010-2bef77559c98",
        title: "Aloo Baigan",
        price: 5000,
      },

      {
        id: "020cc172-8f90-4d0a-aa61-b6c6ca21abdb",
        title: "Dal Thadka",
        price: 5000,
      },

      {
        id: "08b8da01-f00f-4ab2-916c-24bfbd05c306",
        title: "Plain Naan ",
        price: 2500,
      },

      {
        id: "1bb4a6f6-b534-462f-8ccc-d8d875c79ef0",
        title: "Garlic Naan/Butter Naan ",
        price: 3000,
      },
    ],
  },
  chinese: {
    id: 13,
    recipes: [
      {
        id: "03ec5d11-bb62-48a3-894c-3c510abe4df5",
        title: "Ginger Chicken Full ",
        price: 15000,
      },

      {
        id: "63c8136b-e28f-4a0e-b09e-510b31349bd2",
        title: "Ginger Chicken Half ",
        price: 8000,
      },
      {
        id: "cc6789cf-3d76-4985-8c09-f047c5ac93f2",
        title: "Chicken Manchurian/Fish ",
        price: 8000,
      },

      {
        id: "ca12a357-3b71-4b45-8843-9636d4d27f84",
        title: "Schezwan Chicken/Fish ",
        price: 8000,
      },

      {
        id: "bc043364-3108-445d-8227-f6450a58de7b",
        title: "Chilly Chicken ",
        price: 8000,
      },

      {
        id: "e758469a-01e0-40c3-ac75-5d2f14c19436",
        title: " Schezwan Vegetable  ",
        price: 6000,
      },

      {
        id: "4fb5760c-bce3-406e-9ca2-b6626460b364",
        title: " Cauliflower Manchurian  ",
        price: 6000,
      },
    ],
  },
  pasta: {
    id: 14,
    recipes: [
      {
        id: "80ae965b-7ad5-457b-b812-c0e532d6f98f",
        title: "Spaghetti Bolognaise ",
        price: 6000,
      },
      {
        id: "140176c7-c30a-41f8-a802-b5b8e91d786e",
        title: "Spaghetti Napolitano ",
        price: 5000,
      },
      {
        id: "1c8eafc8-62ce-4d88-b79d-2b0b07daa873",
        title: "Alfredo ",
        price: 6000,
      },

      {
        id: "a78abc26-628b-462a-ab38-3c36506cf49b",
        title: "Spaghetti Carbonara ",
        price: 6000,
      },

      {
        id: "53e60a77-3aad-43db-9135-62ae95fa23ab",
        title: "Gnochi ",
        price: 5000,
      },
    ],
  },

  rices: {
    id: 15,
    recipes: [
      {
        id: "12f771fc-7428-40c3-a667-e3831c6950d4",
        title: "Chicken Fried/Noodles  ",
        price: 5000,
      },
      {
        id: "fe9c4b76-5f89-4f58-832d-6da4a2e83637",
        title: "Egg Fried Rice/Vegetable Fried Rice ",
        price: 5000,
      },
      {
        id: "5ae46e14-a08a-4a31-bf5d-d4989d43b40f",
        title: "Jeera Rice ",
        price: 3000,
      },

      {
        id: "50a69e80-c0d8-4069-9cc1-bc9957f85abd",
        title: "Basmati Plain White Rice ",
        price: 2000,
      },
    ],
  },
  pizzas: {
    id: 16,
    recipes: [
      {
        id: "d2ea135f-d4f9-4ac0-8cc3-5aca3141239a",
        title: "Heavenly Veggie",
        price: 5000,
        desc:
          "Melted feta cheese, black olives, tomato, sweet caramalised onions with fresh herbs and a generous spread of cheese",
      },

      {
        id: "0df9e511-a47a-4ab3-9ee2-32b598ae0cd2",
        title: "Meat Lover’s Supreme Delight",
        price: 7000,
        desc:
          "Chicken, black olives, pepperoni, tomatoes, red onions and mixed peppers topped with cheese",
      },

      {
        id: "c1eddab7-b784-4c19-b904-85b29061fe7f",
        title: "Classic Hawaiian",
        price: 6000,
        desc:
          "A Generous portion of diced ham and pineapple baked with a topping of mozzarella cheese",
      },

      {
        id: "f9ba3e54-6aa8-4ff2-aa44-78bf844888c7",
        title: "Sweet Chilli Chicken ",
        price: 7000,
        desc:
          "Chicken, bacon, red onions, herbs, chilli sauce and a deizzle of sweet chilli",
      },

      {
        id: "453c7b44-aba6-4f0f-ab4a-c80dc7d54d4f",
        title: " Palm Beach Special Pizza ",
        price: 7000,
      },

      {
        id: "86573d80-a640-404b-aa4c-ce4f6d6ac4e0",
        title: "Kivu Pizza  ",
        price: 7000,
      },

      {
        id: "4398259c-a9ce-487c-b522-d5c730fbafc9",
        title: "Margaritha Pizza",
        price: 5000,
      },
      {
        id: "101a64e9-0276-4eac-901d-1e164aa4b215",
        title: "Capriccioza Pizza",
        price: 6000,
      },
    ],
  },
  sandwitches: {
    id: 17,
    recipes: [
      {
        id: "2cdbaa8f-ab19-4dd1-9474-ddff241a0015",
        title: "Cheese",
        price: 3000,
      },
      {
        id: "249bdf13-a386-4f79-a0d3-2666818754fd",
        title: "Cheese & Tomato",
        price: 3000,
      },

      {
        id: "6b7cb103-87e1-4612-897c-1aca113c1d5b",
        title: "Ham",
        price: 3500,
      },

      {
        id: "2ffe65a6-8fef-47ed-a60f-790fdcb11d5f",
        title: "Bacon, Cheese & Tomato",
        price: 4000,
      },

      {
        id: "2e414e83-10da-407c-84c3-ee0b5d678af9",
        title: "Bacon, Cheese & Tomato",
        price: 4000,
      },
      {
        id: "e309445d-53ac-4284-82ef-eb9a439afc8a",
        title: "Chicken Mayonnaise",
        price: 4000,
      },
      {
        id: "ca5b5024-d0e8-4463-93fa-01b0cf370ba0",
        title: "Bacon & Egg",
        price: 4000,
      },
      {
        id: "5c66c948-4c4f-43b5-a9f8-047fd819a771",
        title: "Club Sandwich",
        price: 5000,
      },

      {
        id: "5c66c948-4c4f-43b5-a9f8-047fd819a771",
        title: "Pulled Pork Sandwich",
        price: 5000,
      },
    ],
  },
  hot_drinks: {
    id: 18,
    recipes: [
      {
        id: "20cbaaa2-6f97-412c-bc7e-c3b94ad54ac5",
        title: "English Tea / Milk ",
        price: 2000,
      },
      {
        id: "37111330-1ed9-4f0a-95f0-935ce9195ad1",
        title: "Green Tea",
        price: 2000,
      },
      {
        id: "f6e679e4-3396-42f1-8a4e-7ed6fb0ec7ea",
        title: "African Tea",
        price: 3000,
      },

      {
        id: "2d45dcbd-8ec8-487c-bccc-b915ef393dcc",
        title: " Nescafe ",
        price: 3000,
      },

      {
        id: "a811fe71-2fde-471b-a73c-b02475aad17d",
        title: "Filter Coffee  ",
        price: 3000,
      },
      {
        id: "59fd98f1-4239-441d-a9da-e604858c87f4",
        title: "Hot Chocolate",
        price: 3500,
      },
    ],
  },
  burgers: {
    id: 19,
    recipes: [
      {
        id: "becdb056-f086-479e-9405-d75d030e6344",
        title: " Beef Burger ",
        price: 5000,
      },

      {
        id: "8d58e2f9-ff68-4143-aedb-89e5af85f796",
        title: " Chicken Burger ",
        price: 5000,
      },
      {
        id: "9214a163-163c-462d-9df8-144164ccfe5e",
        title: " Chicken Hawaiian Burger  ",
        price: 5500,
      },
      {
        id: "382cab92-21a4-4ff4-82c3-9dffee43f056",
        title: " Cheese Burger  ",
        price: 5000,
      },

      {
        id: "0d872269-1edd-40a2-bd2f-9410b3a2a5a6",
        title: " Veggie Burger  ",
        price: 4000,
      },
    ],
  },
  desserts: {
    id: 20,
    recipes: [
      {
        id: "7f40c7a7-19f2-487e-830d-220eea340260",
        title: " Banana Flambé",
        price: 3000,
      },
      {
        id: "64b11e2a-8398-4ddd-bb64-ee18658763c1",
        title: "Fruit Salad & Ice cream",
        price: 4500,
      },
      {
        id: "5a351a33-d8c1-4c97-a86b-13fae53aca59",
        title: "Fruit Salad",
        price: 4000,
      },

      {
        id: "dc75e2b2-af72-4e6c-b7d9-e0cf0e4f7861",
        title: "Waffles",
        price: 4000,
      },
    ],
  },
  juices: {
    id: 21,
    recipes: [
      {
        id: "f0d79d67-82d6-4410-a1fc-b7a51cd6015a",
        title: "Pineapple",
        price: 3000,
      },
      {
        id: "d4526e6e-3b85-445f-bbc4-95c4612199e6",
        title: "Passion Fruit/Mixed Fruit",
        price: 3000,
      },

      {
        id: "a2ea8103-4215-4be1-a9fd-f2a948812ec6",
        title: "Tree Tomato",
        price: 3000,
      },
    ],
  },
}

export const foodMenuLabels = [
  {
    id: 1,
    value: "breakfast",
    label: "Breakfast",
  },
  {
    id: 2,
    value: "starters",
    label: "Starter",
  },

  {
    id: 3,
    value: "salads",
    label: "Salads",
  },

  {
    id: 4,
    value: "soups",
    label: "Soups",
  },

  {
    id: 5,
    value: "brochettes",
    label: "Brochettes",
  },

  {
    id: 6,
    value: "beefs",
    label: " Burley Beef",
  },

  {
    id: 7,
    value: "porks",
    label: "Perky Pork",
  },

  {
    id: 8,
    value: "chickens",
    label: "Chicken Coup",
  },

  {
    id: 9,
    value: "fishes",
    label: "Something Fishy",
  },

  {
    id: 10,
    value: "rwanda",
    label: "Rwanda Relish",
  },

  {
    id: 11,
    value: "accompaniments",
    label: "Accompaniments",
  },

  {
    id: 12,
    value: "eastern",
    label: "Eastern Cuisine",
  },

  {
    id: 13,
    value: "chinese",
    label: "Chinese chow",
  },
  {
    id: 14,
    value: "pasta",
    label: "Pasta",
  },

  {
    id: 15,
    value: "rices",
    label: "Rice/Noodles",
  },
  {
    id: 16,
    value: "pizzas",
    label: "Pizza",
  },
  {
    id: 17,
    value: "sandwitches",
    label: "Sandwitches",
  },

  {
    id: 18,
    value: "hot_drinks",
    label: "Hot Drinks",
  },

  {
    id: 19,
    value: "burgers",
    label: "Burgers",
  },

  {
    id: 20,
    value: "desserts",
    label: "Deserts",
  },

  {
    id: 21,
    value: "juices",
    label: "Fresh Juice",
  },
]
