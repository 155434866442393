export const menuAlcoholDrink = {
  beer: {
    id: 1,
    recipes: [
      {
        id: "3b08d81b-ab53-4391-a3ff-30b6ec0b932f",
        title: "Primus - BIG",
        price: 2500,
      },

      {
        id: "8960fde8-21e4-4853-85eb-91f242b7e7f3",
        title: "Primus - CITRON",
        price: 2000,
      },

      {
        id: "c34574af-d3dd-498a-b24d-377a0fd78f48",
        title: "Primus - 50CL",
        price: 2000,
      },

      {
        id: "07d28915-9a87-4936-8ccb-3f1a793070a2",
        title: "Knowless",
        price: 2000,
      },

      {
        id: "18ae45dd-ee65-4647-8f82-c44fb4d2f213",
        title: "Mutzig- BIG",
        price: 2500,
      },

      {
        id: "aa2bd2bb-51de-4f8c-9187-a9f1a1942e55",
        title: "Mutzig- SMALL",
        price: 1500,
      },

      {
        id: "ed5d1c58-f353-404a-8d4e-eec7f594e58a",
        title: "Mutzig- 50CL",
        price: 2000,
      },
      {
        id: "62e0b7ae-e4eb-4538-a2b7-297cd772c755",
        title: "Primus",
        price: 1500,
      },

      {
        id: "3d2bd7bd-d471-4b4f-965b-9f04b32ab0d7",
        title: "Heinkein",
        price: 2000,
      },

      {
        id: "d0b04299-db23-4537-b411-0705f2b1c518",
        title: "Senator",
        price: 2000,
      },

      {
        id: "d2c4b93b-17d9-40ce-8ef5-9ca032625dc0",
        title: "Senator",
        price: 2000,
      },

      {
        id: "35ab6094-2419-4936-86c5-46e82ca7df62",
        title: "Guiness",
        price: 3000,
      },

      {
        id: "dc4ee4d9-8744-4eba-8db8-f05c7060d1c5",
        title: "Leffe",
        price: 4000,
      },
      {
        id: "522cd91f-be8c-42c7-b968-34745a097b4d",
        title: "Turbo King",
        price: 1500,
      },

      {
        id: "6f8d5e86-56e8-4293-b2e3-1f168a11f7a0",
        title: "Legend",
        price: 1500,
      },
      {
        id: "1248d29a-2c6d-400d-8dda-f522f514f0bf",
        title: "Tusker Lager",
        price: 2000,
      },

      {
        id: "f21d7a9d-e1a0-4950-bb10-db173bbf2ef3",
        title: "Tusker Malt",
        price: 2000,
      },
      {
        id: "181b57d1-ba0a-420e-bc49-5e2d0d4962df",
        title: "Brok - CANETH",
        price: 3000,
      },

      {
        id: "ae1245bc-52c9-4292-9a9e-0989f128aa89",
        title: "Skol Malt - BIG",
        price: 2500,
      },

      {
        id: "d3d7592e-915f-4dfb-b535-e80ac796b36b",
        title: "Skol Malt - SMALL",
        price: 1500,
      },
      {
        id: "4dbf2724-74e0-486c-a15f-079f09e68532",
        title: "Skol Lager - 50CL",
        price: 2000,
      },

      {
        id: "4f16378f-4191-437f-97b0-46179d983298",
        title: "Gatanu - BIG",
        price: 2500,
      },

      {
        id: "b782f7fd-9259-4f1b-8304-89225699efc4",
        title: "Gatanu - SMALL",
        price: 1500,
      },

      {
        id: "4fb2f02d-7748-42f1-a9aa-9e841434207d",
        title: "Virunga Mist",
        price: 2000,
      },
    ],
  },

  aperitif: {
    id: 2,
    recipes: [
      {
        id: "986b26f5-ac29-4ca1-b2bd-ac37c4343aeb",
        title: "Martini Blanco - 125ML",
        price: 6000,
      },

      {
        id: "87ea6b27-b193-4464-a783-91fd33b0bf48",
        title: "Martini Rosso - 125ML",
        price: 6000,
      },
    ],
  },

  whisky: {
    id: 3,
    recipes: [
      {
        id: "d42ace3f-9c06-427d-bf58-e0787fd635cf",
        title: "Bond-7 - QUARTER",
        price: 5000,
      },

      {
        id: "de29c5de-1da6-45dc-9a9d-601da028e9de",
        title: "Bond-7 - TOT",
        price: 2000,
      },

      {
        id: "22aa651a-bee6-4267-a82d-9ac75bc54b96",
        title: "Chivas Regal - TOT",
        price: 3500,
      },

      {
        id: "22aa651a-bee6-4267-a82d-9ac75bc54b96",
        title: "Chivas Regal - TOT",
        price: 3500,
      },

      {
        id: "b8d3fedd-c8a0-4393-9638-03129de9a1ed",
        title: "J&B - QUARTER",
        price: 3500,
      },

      {
        id: "2c77fc25-ee12-48dc-84a6-3078cf4704f9",
        title: "J&B - TOT",
        price: 3000,
      },

      {
        id: "18d2e088-81d1-4a02-8d2d-940b976d814e",
        title: "Jack Daniels - TOT",
        price: 4500,
      },

      {
        id: "ec74160a-1687-4446-a248-9aa258636f6d",
        title: "LW Black label - TOT",
        price: 3500,
      },

      {
        id: "44cc446b-f4d7-40d6-aa8a-87d47ceb93c3",
        title: "LW Red label - TOT",
        price: 3500,
      },
    ],
  },
  vodka: {
    id: 4,
    recipes: [
      {
        id: "32806b3c-23e1-4189-8bab-4094c0399646",
        title: "Absolut Vodka - TOT",
        price: 3500,
      },
      {
        id: "3a9eb8db-bb1c-44ef-8cc7-cbb29dcfb715",
        title: "Absolut Mandarin - TOT",
        price: 3500,
      },

      {
        id: "5d2fe6fc-97d5-4f39-b27a-887d058b5a69",
        title: "Smirnoff - ICE",
        price: 3000,
      },

      {
        id: "014f8647-9c54-4dca-91f6-042977a46d9f",
        title: "Smirnoff - CANETH",
        price: 3000,
      },

      {
        id: "014f8647-9c54-4dca-91f6-042977a46d9f",
        title: "Smirnoff - CANETH",
        price: 3000,
      },
    ],
  },

  tequila: {
    id: 6,
    recipes: [
      {
        id: "bfc3c19c-dac0-48e6-86a3-dbac76c519e0",
        title: "Camino - TOT",
        price: 3500,
      },

      {
        id: "1103f4d3-9572-490c-bebd-6e449da3694c",
        title: "Patron - TOT",
        price: 3500,
      },

      {
        id: "e1c980bb-b6ea-4bde-85e9-633d80d8d60e",
        title: "Jose Curevo - TOT",
        price: 3500,
      },
    ],
  },

  rum: {
    id: 5,
    recipes: [
      {
        id: "17dc424d-51d1-460f-970a-3f630758fa83",
        title: "Bacardi Gold - TOT",
        price: 3500,
      },

      {
        id: "a5d0a38d-6a81-44d9-a459-990505592ec9",
        title: "Bacardi White - TOT",
        price: 3500,
      },

      {
        id: "2f56b23d-1a17-41c2-b528-12a89872944a",
        title: "Captain Morgan - TOT",
        price: 3500,
      },
      {
        id: "c3eb4a85-5d34-4532-9414-fbb8a4313001",
        title: "Malibu - TOT",
        price: 3500,
      },
    ],
  },
}

export const menuAlcoholDrinkLabels = [
  {
    id: 1,
    value: "beer",
    label: "Beer",
  },
  {
    id: 2,
    value: "aperitif",
    label: "Aperitif",
  },

  {
    id: 3,
    value: "whisky",
    label: "Whisky",
  },
  {
    id: 4,
    value: "vodka",
    label: "Vodka",
  },

  {
    id: 5,
    value: "rum",
    label: "Rum",
  },

  {
    id: 6,
    value: "tequila",
    label: "Tequila",
  },
]
