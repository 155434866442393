import React from "react"

const SidebarNav = ({ data, activeCategory, onChange }) => {
  return (
    <ul>
      {data.map(el => {
        const labelColor =
          activeCategory === el.value ? "text-red-700" : "text-gray-700"
        const labelWeight =
          activeCategory === el.value ? "font-medium" : "font-normal"
        return (
          <li key={el.id} class="cursor-pointer">
            <span
              onClick={() => onChange(el.value)}
              class={`${labelColor} ${labelWeight}  leading-8`}
            >
              {" "}
              {el.label}
            </span>
          </li>
        )
      })}
    </ul>
  )
}

export default SidebarNav
